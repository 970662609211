@import "../global/typography";
@import "../global/colors";

*,
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: $background;
}
