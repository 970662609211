@import "../../../../styles/global/colors";
@import "../../../../styles/global/typography";

.view {
  display: flex;
  align-items: center;
  background: #ebf2ff;
  border-radius: 4px;
}
.view__item {
  padding: 9px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.view--list {
  padding: 11px 18px;
}
.view--grid {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(27, 78, 163, 0.24),
    0px 2px 4px rgba(41, 121, 255, 0.24);
  border-radius: 4px;
}
