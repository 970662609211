@font-face {
  font-family: "Inter";
  src: url("./Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./Quicksand-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url("./RedHatDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}