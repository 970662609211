@import "../../../styles/global/colors";
@import "../../../styles/global/typography";

.main__rightbar {
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
    0px 2px 4px rgba(90, 91, 106, 0.24);
  border-radius: 8px;
  padding: 16px;
}
.main__rightbar__product{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    background: #ffffff;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}
.top__section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.top__section__right{
  display: flex;
  align-items: center;
  gap: 20px;
}