@import "../../styles/global/colors";
@import "../../styles/global/typography";

.header {
  max-width: 1350px;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 80px;
}
.header__logo {
  display: flex;
  align-items: center;
  font-family: $Red-Hat-Display;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
}
.header__logo__icon {
  height: 40px;
  width: 40px;
  font-weight: bold;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(90deg, #2979ff 0%, #4c589e 100%);
  border-radius: 6px;
  margin-right: 10px;
}

.header__search {
  width: 380px;
  position: relative;
  padding: 9px 16px;
  background: #ededf0;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header__search__inp {
    border: none;
    background: none;
    height: 30px;
    outline: none;
    flex: 0.9;
  }
  img{
      background: transparent;
  }
}
.header__consumer {
  font-family: $Inter;
  font-size: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #2264d1;
  margin: 0px 8px;
}
.header__signin__mycart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.header__sigin__button {
  padding: 6px 12px;
  border: 1px solid #9dc2ff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $Quicksand;
  background-color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.0075em;
  color: #2264d1;
  cursor: pointer;
  outline: none;
}
.header__mycart__button {
  padding: 7px 12px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(27, 78, 163, 0.2),
    0px 4px 8px rgba(41, 121, 255, 0.2);
  border-radius: 4px;
  font-family: $Quicksand;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.0075em;
  color: #2264d1;
  cursor: pointer;
  border: none;
  outline: none;
}
.header__user {
  height: 40px;
  width: 40px;
  border-radius: 99px;
  position: relative;

  .header__user__notification {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background: #f44336;
    border-radius: 99px;
    font-family: $Quicksand;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
