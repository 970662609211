$white: #ffffff;
$black: #000000;
$background: #ffffff;
$yellow: #f6c937;
$red: #ff0066;
$blue: #2b2ecf;
$violet: #652bcf;
$cyan: #37f6b8;
$grey: #7f8a9c;
$secondary-grey: #c5d3e8;
$gradient: linear-gradient(135deg, #2b2ecf 0%, #652bcf 100%);
