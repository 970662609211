@import "../../../../styles/global/colors";
@import "../../../../styles/global/typography";

.relatedproduct {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  background-color: #fff;
}
.relatedproduct__text {
  font-family: $Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.018em;
  color: #19191d;
  background-color: #fff;
}

.relatedproduct__item {
  font-family: $Inter;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.018em;
  color: #4a4b57;
  background: #ededf0;
  border-radius: 99px;
  padding: 6px 12px;
  margin-left: 8px;
  cursor: pointer;
}
