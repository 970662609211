@import "../../../../styles/global/colors";
@import "../../../../styles/global/typography";

.product {
  padding: 12px;
  background-color: #fff;

  &:hover {
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(59, 69, 123, 0.2),
      0px 4px 8px rgba(92, 107, 192, 0.2);
    border-radius: 8px;
  }
}
.product__image {
  background-color: #fff;
}
.product__image__item {
  width: 100%;
}
.product__description {
  font-family: $Inter;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0275em;
  color: #19191d;
  background-color: #fff;
  margin-bottom: 8px;
  min-height: 100px;
}
.product__price {
  font-family: $Inter;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  background-color: #fff;
}
.product__additionaldesc {
  font-family: $Inter;
  font-size: 14px;
  line-height: 143%;
  background-color: #fff;
  letter-spacing: 0.018em;
  color: #787885;
  margin-bottom: 8px;
  min-height: 40px;
}
.product__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.product__footer__rating {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
}
.product__footer__rating__star {
  background-color: #fff;
}
.product__footer__rating__value {
  font-family: $Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #5e6366;
  background-color: #fff;
}
.product__footer__favbutton {
  background: #ffffff;
  border: 1px solid #9dc2ff;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: $Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.018em;
  color: #2264d1;

  .product__footer__favbutton_heart {
    margin-right: 6px;
  }
}
