@import "../../../../styles/global/colors";
@import "../../../../styles/global/typography";

.segment__button {
  font-family: $Inter;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.018em;
  color: #5a5b6a;
  background: #ebf2ff;
  padding: 6px 12px;
  border-radius: 4px 0 0 4px;
  border: 0;
  outline: none;
  cursor: pointer;
}
.segment--auction {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(27, 78, 163, 0.24),
    0px 2px 4px rgba(41, 121, 255, 0.24);
  border-radius: 4px;
  color: #2264d1;
}
.segment--buynow {
  border-radius: 0 4px 4px 0;
}
