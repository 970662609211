@import "../../../../styles/global/typography";
@import "../../../../styles/global/colors";

.categories {
  margin-bottom: 28px;
}
.categories__tree {
  .MuiTreeItem-root.Mui-selected
    > .MuiTreeItem-content
    .MuiTreeItem-label:hover,
  .MuiTreeItem-root.Mui-selected:focus
    > .MuiTreeItem-content
    .MuiTreeItem-label,
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background: none;
  }
  .MuiListItemText-multiline {
    margin-top: 0;
    margin-bottom: 12px;
    .MuiTypography-body1 {
      font-weight: normal;
    }
  }
  .MuiTreeItem-iconContainer {
    margin-right: 12px;
  }
  .MuiTypography-body1 {
    font-family: $Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0275em;
    color: #19191d;
  }
  .MuiTypography-body2 {
    font-family: $Inter;
    font-size: 14px;
    line-height: 143%;
    display: flex;
    align-items: center;
    letter-spacing: 0.018em;
    color: #787885;
  }
  .MuiTreeItem-iconContainer {
    svg {
      font-size: 24px;
      color: #2264d1;
    }
  }

  .MuiTreeItem-group {
    .MuiCollapse-wrapper {
      .MuiCollapse-wrapperInner {
        .MuiTreeItem-root {
          .MuiTreeItem-content {
            margin-bottom: 12px;

            .MuiListItemText-multiline {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .MuiTreeItem-root.Mui-expanded.Mui-selected {
    .MuiCollapse-container.MuiTreeItem-group.MuiCollapse-entered {
      .MuiTreeItem-content {
        .MuiTypography-body1 {
          font-weight: 400;
        }
      }
    }
  }
}

.MuiTreeItem-root.categories__tree__item {
  margin-left: -17px;
}

.categories__tree__showmore__button {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background: #ebf2ff;
  border-radius: 4px;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-bottom: 16px;
  margin-left: 13px;
  font-family: $Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  color: #2264d1;

  &.button--expand {
    margin-left: 30px;
    padding: 10px 12px;
  }
  .MuiSvgIcon-root {
    margin-left: 10px;
    color: #787885;
  }
}
